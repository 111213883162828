<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Klasifikasi Surat Dokumen</h3>
            <hr class="m-t-0 m-b-40">
            <div class="row">
                <div class="col-md-8">
                    <div class="form-group row">
                        <div class="col-md-8">
                            <Input v-model="payload.idsurat" name="idsurat" disabled required type="hidden"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                    Induk Klasifikasi Tree
                  </label>
                  <div class="col-md-8">
                    <treeselect v-model="payload.klasifikasi" :options="indukklasifikasi" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
                <div class="col-md-8">
                    <div class="form-group row">
                        <label :class="['control-label', {'text-right': !isMobile}, 'col-md-3']">
                            Nama Berkas
                        </label>
                        <div :class="['col-md-8', {'invalid': isInvalidNamaFolder}]">
                            <Multiselect
                                v-model="payload.namafolder"
                                label="text"
                                track-by="value"
                                placeholder="Pilih Klasifikasi Sekunder"
                                open-direction="bottom"
                                :options="dropdownNamaFolder.namafolderList"
                                :multiple="false"
                                :searchable="true"
                                :value="dropdownNamaFolder.value"
                                :loading="dropdownNamaFolder.isLoadingNamaFolder"
                                :internal-search="false"
                                :clear-on-select="true"
                                :close-on-select="true"
                                :options-limit="300"
                                :max-height="600"
                                :show-no-results="true"
                                :hide-selected="true"
                                @close="onTouchNamaFolder"
                                @input="onChangeNamaFolder"
                                @search-change="namafolder"
                            >
                                <span slot="noResult">Oops! No Data found.</span>
                            </Multiselect>
                            <label 
                                class="typo__label from__label"
                                v-show="isInvalidNamaFolder"
                            >Must have at least one value</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <div class="form-group row">
                  <label class="control-label text-right col-md-3">
                    Tag
                  </label>
                  <div class="col-md-8">
                    <vue-tags-input
                      v-model="tag"
                      :tags="tags"
                      @tags-changed="newTags => tags = newTags"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label :class="['control-label']">File Tambahan</label>
                  <h6 v-if="!files.length">{{ $t('no_file') }}</h6>
                  <table v-else class="table table-stripped">
                    <tr>
                      <th>{{ $t('file') }}</th>
                      <th>{{ $t('action') }}</th>
                    </tr>
                    <tr v-for="file in files" v-bind:key="file.filename">
                      <td>
                        {{ file.filepath !== undefined ? file.filename:file.name }}
                      </td>
                      <td>
                        <span v-if="file.filepath !== undefined">
                          <a
                            class="btn btn-success text-white"
                            @click="download(file.uuid,file.filename)"
                          ><i class="fa fa-download text-white"></i>
                          </a>
                          <a
                            class="btn btn-info text-white"
                            @click="prev(file.uuid)"
                          ><i class="fa fa-eye text-white"></i>
                          </a>
                        </span>
                        <i><b>{{ $t('not_saved') }} &nbsp;&nbsp;</b></i>
                        <a class="btn btn-danger text-white"
                        @click="removeAttachment(file.uuid)">
                          <i class="fa fa-trash-o text-white"></i>
                        </a>
                      </td>
                    </tr>
                  </table>
                  <Gallery :uploader="uploader"/>
                </div>
              </div>
            </div>

          </div>
          <hr>
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button type="submit" class="btn btn-success" title="Submit">Submit</button>&nbsp;
                    <button type="button" @click="goBack()" class="btn btn-inverse" title="Cancel">Cancel</button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script src="https://unpkg.com/@johmun/vue-tags-input/dist/vue-tags-input.js"></script>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Multiselect from "vue-multiselect";
import VueTagsInput from '@johmun/vue-tags-input';
import Upload from "@/components/input/upload.vue";
import Input from "@/components/input/input";
import axios from "axios";

export default {
  components: {
    Input,
    RotateSquare5,
    Multiselect,
    Upload,
    VueTagsInput,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    isInvalidNamaFolder() {
        return (
            this.dropdownNamaFolder.isTouched &&
            this.dropdownNamaFolder.value.length === 0
        );
    },
    state() {
      return this.$store.state.clasificationdoc;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isinvalidTipe() {
      return this.isTouched && this.payload.valueTipe.length === 0;
    }
  },
  data() {
    const uploader = this.fineUploader(this, "pemberkasan");
    return {
      tag: '',
      tags: [],
      value: null,
      idsurat: this.$route.params.id,
      suratid: 0,
      uploader,
      indukklasifikasi: this.$store.state.clasificationdoc.items.treeklasifikasi,
      payload: {
        id_surat: this.$route.params.id,
        surat: this.$route.params.id,
        primer: [],
        sekunder: [],
        tersier: [],
        klasifikasi: [],
        namafolder: [],
        tag: "",
      },
      dropdownKlasifikasi: {
        isTouched: false,
        klasifikasiList: [],
        value: [],
        isLoadingKlasifikasi: false
      },
      dropdownNamaFolder: {
          isTouch: false,
          namafolderList: [],
          value: [],
          isLoadingNamaFolder: false
      },
      isDisabled: false,
      optionTipe: [],
      files: [],
    };
  },
  async mounted() {
    this.initialize();
    this.getAllReference();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          axios
            .get(`/pemberkasan/${this.$route.params.id}`)
            .then(response => {
              this.payload.idsurat = this.$route.params.id;
              const state = {
                loaded: true
              };
              this.$store.commit("clasificationdoc/STATE", state);
              this.edit(response.data.data);
            });
        } else {
          this.$store.dispatch("clasificationdoc/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    removeAttachment(uuid){
     let vm = this;
     let files = vm.uploader.methods.getUploads();
     files.map(element=>{
       if (element.uuid == uuid) {
         let folder;
         vm.files.map(ele=>{
           if (ele.uuid == uuid) {
             folder = ele.folder;
           }
         });
         vm.uploader.methods.setDeleteFileParams({'folder': folder},element.id);
         vm.uploader.methods.deleteFile(element.id);
       }
     })
     vm.files = vm.files.filter(element=>{
       return element.uuid != uuid;
     });
    },
    getAllReference(){
      var query = "";
      this.namafolder(query);
    },
    _onInputExtendedCost: function($event) {
        this.suratid = parseInt($event.target.value);
        // Go update other inputs here
    },
    edit(data) {
      this.payload = {
        idsurat: this.$route.params.id
      };
    },
    goBack() {
      this.$store.dispatch("pemberkasan/onCancel");
    },
    onTouchKlasifikasi() {
      this.dropdownKlasifikasi.isTouched = true;
    },
    onTouchNamaFolder() {
        this.dropdownNamaFolder.isTouched = true;
    },
    onTouch() {
      this.isTouched = true;
      this.isTouchedInduk = true;
      this.isTouchedTujuan = true;
    },
    onChangeKlasifikasi(value) {
      this.dropdownKlasifikasi.value = value;
    },
    onChangeNamaFolder(value) {
        this.dropdownNamaFolder.value = value;
    },
    onChangeTipe(value) {
      this.payload.valueTipe = value;
    },
    namafolder(query) {
        this.dropdownNamaFolder.isLoadingNamaFolder = true;
        axios
            .get(`daftarfolder/list?s=${query}`)
            .then(res => {
                this.dropdownNamaFolder.namafolderList = res.data.items;
                this.dropdownNamaFolder.isLoadingNamaFolder = false;
            }).catch(err => {
                if (err.response.status === 401) {
                    store.commit("auth/LOGOUT");
                }
            });
    },

    submit() {
      const payload = {
      };
      const data = JSON.stringify(this.payload);
      this.$validator.validateAll().then(success => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("pemberkasan/submitBerkas", {data, id: this.$route.params.id});
          } else {
            this.$store.dispatch("pemberkasan/submitBerkas", {data, id: this.$route.params.id});
          }
        }
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
.normal-background {
  background-color: #fff !important;
}
</style>

